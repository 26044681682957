import React, { useCallback, useEffect, useRef, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import axios from "axios";
import { ResponsiveLine } from "@nivo/line";

import Utilities from "../lib/Utilities";

import { MsgIDList } from "../lib/BuddibleSocket";

const util = new Utilities();

const _serverUrlList = [
  "https://system.gbts.co.kr:9910/monitor",
  "https://talktalk.gbts.co.kr:9910/monitor",
];

const _serverList = [
  {
    lineCharts: [
      {
        id: "cpuHistory",
        desc: "CORES 4 LOAD 64.06%",
        data: [
          {
            x: "17:38:18",
            y: 40,
            realValue: 40.1419654877004,
          },
          {
            x: "17:38:28",
            y: 40,
            realValue: 40.52135601517562,
          },
          {
            x: "17:38:38",
            y: 41,
            realValue: 41.391261294714695,
          },
          {
            x: "17:38:48",
            y: 35,
            realValue: 35.908141962421716,
          },
          {
            x: "17:38:58",
            y: 42,
            realValue: 42.29249011857708,
          },
          {
            x: "17:39:08",
            y: 43,
            realValue: 43.878042901904074,
          },
        ],
        load: 64.05716060888474,
        cores: 4,
        name: "CPU HISTORY",
        keys: ["load"],
      },
      {
        id: "memoryHistory",
        desc: "사용량 : 91.95% 1.82/1.98 GB",
        data: [
          {
            x: "17:38:18",
            y: 91,
            realValue: 91.972513630427,
          },
          {
            x: "17:38:28",
            y: 91,
            realValue: 91.97912232230398,
          },
          {
            x: "17:38:38",
            y: 91,
            realValue: 91.97574561842524,
          },
          {
            x: "17:38:48",
            y: 91,
            realValue: 91.97328544845642,
          },
          {
            x: "17:38:58",
            y: 91,
            realValue: 91.89460824808161,
          },
          {
            x: "17:39:08",
            y: 91,
            realValue: 91.95982687156827,
          },
        ],
        free: 1366360064,
        total: 16982241280,
        used: 15615881216,
        percentUsed: 91.95418295222808,
        name: "MEMORY HISTORY",
        keys: ["percentUsed"],
      },
    ],
    pieCharts: [
      {
        id: "DISKS",
        fs: "C:",
        type: "NTFS",
        size: 103076065280,
        used: 92344254464,
        available: 10731810816,
        use: 89.59,
        mount: "C:",
        rw: true,
        desc: "사용량 : 89.59% 10.75/12.00 GB",
        charts: [
          {
            id: "USAGE",
            value: 89.58845510172738,
            color: "#0097B7",
          },
          {
            id: "AVAILABLE",
            value: 10.411544898272625,
            color: "#334D80",
          },
        ],
      },
    ],
    platform: "win32",
    release: "10.0.14393",
    arch: "x64",
  },
];
let loadCount = 1;
let sv = [];

export default function Test() {
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isDisplayReset, setIsDisplayReset] = useState(false);
  const [serverList, setServerList] = useState([..._serverList]);

  useEffect(() => {
    getHardwareMonitor();
    const intervalRequest = setInterval(() => {
      getHardwareMonitor();
    }, 1000 * 10);
    return () => {
      clearInterval(intervalRequest);
    };
  }, []);
  function getHardwareMonitor() {
    const username = "admin"; // .env 파일의 VALID_USERS에 정의된 사용자 이름
    const password = "GbtS9880!@"; // .env 파일의 VALID_USERS에 정의된 비밀번호
    const base64Credentials = Buffer.from(`${username}:${password}`).toString(
      "base64"
    );
    const authHeader = `Basic ${base64Credentials}`;
    if (loadCount === 60 * _serverUrlList.length) {
      loadCount = 1;
      setIsDisplayReset(true);
    }

    _serverUrlList.forEach((url, index) => {
      axios
        .get(url, {
          headers: {
            Authorization: authHeader,
          },
        })
        .then((response) => {
          setData(response.data, (data) => {
            if (
              sv.length === _serverUrlList.length &&
              sv.findIndex((i) => !i) === -1
            ) {
              setServerList(sv);
              sv = [];
            } else {
              sv[index] = data;
            }
          });
        })
        .catch((error) => {
          console.error(
            "Error fetching monitoring information:",
            error.response ? error.response.data : error.message
          );
        })
        .finally(() => {
          loadCount++;
          setIsDisplayReset(false);
        });
    });
  }

  function getTime(date) {
    const time = new Date(date);
    const hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
    const min =
      time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
    const sec =
      time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
    return `${hour}:${min}:${sec}`;
  }

  function bytesToGigabytes(bytes) {
    return bytes / 1024 ** 3;
  }

  function setData(data, callback) {
    let temp = {};
    for (let key in data) {
      if (key === "os") {
        temp = {
          ...temp,
          ...data[key],
        };
      }

      if (key === "memory" || key === "cpu") {
        const isCPU = key === "cpu";
        const hisKey = key + "History";
        let desc = ""; //`CORES ${data[key].cores} LOAD ${data[key].load}\n`;
        if (isCPU) {
          desc = `CORES ${data[key].cores} LOAD ${data[key].load.toFixed(2)}%`;
        } else {
          desc = `사용량 : ${data[key].percentUsed.toFixed(
            2
          )}% ${util.addCommas(
            bytesToGigabytes(data[key].used).toFixed(2)
          )}/${util.addCommas(
            bytesToGigabytes(data[key].total).toFixed(2)
          )} GB`;
        }

        const setData = {
          id: key,
          desc: desc,
          data: [],
          ...data[key],
        };

        if (temp.hasOwnProperty("lineCharts")) {
          const index = temp["lineCharts"].findIndex(
            (bar) => bar.id === hisKey
          );

          if (index > -1) {
            temp["lineCharts"][index] = {
              ...setData,
              ...temp["lineCharts"][index],
            };
          } else {
            temp["lineCharts"].push(setData);
          }
        } else {
          temp["lineCharts"] = [setData];
        }
      }

      if (key === "cpuHistory" || key === "memoryHistory") {
        const isCPU = key === "cpuHistory";
        const name = isCPU ? "CPU HISTORY" : "MEMORY HISTORY";
        const keys = isCPU ? ["load"] : ["percentUsed"];

        let pushData = {
          id: key,
          name: name,
          keys: keys,
          data: [],
        };

        const sliceData = data[key].slice(-6);
        for (let j = 0; j < sliceData.length; j++) {
          const y = isCPU ? sliceData[j].load : sliceData[j].percentUsed;
          pushData.data.push({
            x: getTime(sliceData[j].timestamp),
            y: parseInt(y),
            realValue: y,
          });
        }

        if (temp.hasOwnProperty("lineCharts")) {
          temp["lineCharts"].push(pushData);
        } else {
          temp["lineCharts"] = [{ ...pushData }];
        }
      }

      if (key === "disks") {
        temp["pieCharts"] = [];
        for (let j = 0; j < data[key].length; j++) {
          temp["pieCharts"].push({
            id: key.toUpperCase(),
            ...data[key][j],
            desc: `사용량 : ${data[key][j].use}% ${util.addCommas(
              bytesToGigabytes(data[key][j].used).toFixed(2)
            )}/${util.addCommas(
              bytesToGigabytes(data[key][j].size).toFixed(2)
            )} GB`,
            charts: [
              {
                id: "USAGE",
                value: (data[key][j].used / data[key][j].size) * 100,
                color: "#0097B7",
              },
              {
                id: "AVAILABLE",
                value: (data[key][j].available / data[key][j].size) * 100,
                color: "#334D80",
              },
            ],
          });
        }
      }
    }

    callback(temp);
    return;

    /*
    let tempList = [];
    for (let i = 0; i < list.length; i++) {
      const data = list[i];
      let temp = {};

      for (let key in data) {
        if (key === "os") {
          temp = {
            ...temp,
            ...data[key],
          };
        }

        if (key === "memory" || key === "cpu") {
          const isCPU = key === "cpu";
          const hisKey = key + "History";
          let desc = ""; //`CORES ${data[key].cores} LOAD ${data[key].load}\n`;
          if (isCPU) {
            desc = `CORES ${data[key].cores} LOAD ${data[key].load.toFixed(
              2
            )}%`;
          } else {
            desc = `사용량 : ${data[key].percentUsed.toFixed(
              2
            )}% ${util.addCommas(
              bytesToGigabytes(data[key].used).toFixed(2)
            )}/${util.addCommas(
              bytesToGigabytes(data[key].total).toFixed(2)
            )} GB`;
          }

          const setData = {
            id: key,
            desc: desc,
            data: [],
            ...data[key],
          };

          if (temp.hasOwnProperty("lineCharts")) {
            const index = temp["lineCharts"].findIndex(
              (bar) => bar.id === hisKey
            );

            if (index > -1) {
              temp["lineCharts"][index] = {
                ...setData,
                ...temp["lineCharts"][index],
              };
            } else {
              temp["lineCharts"].push(setData);
            }
          } else {
            temp["lineCharts"] = [setData];
          }
        }

        if (key === "cpuHistory" || key === "memoryHistory") {
          const isCPU = key === "cpuHistory";
          const name = isCPU ? "CPU HISTORY" : "MEMORY HISTORY";
          const keys = isCPU ? ["load"] : ["percentUsed"];

          let pushData = {
            id: key,
            name: name,
            keys: keys,
            data: [],
          };

          const sliceData = data[key].slice(-6);
          for (let j = 0; j < sliceData.length; j++) {
            const y = isCPU ? sliceData[j].load : sliceData[j].percentUsed;
            pushData.data.push({
              x: getTime(sliceData[j].timestamp),
              y: parseInt(y),
              realValue: y,
            });
          }

          if (temp.hasOwnProperty("lineCharts")) {
            temp["lineCharts"].push(pushData);
          } else {
            temp["lineCharts"] = [{ ...pushData }];
          }
        }

        if (key === "disks") {
          temp["pieCharts"] = [];
          for (let j = 0; j < data[key].length; j++) {
            temp["pieCharts"].push({
              id: key.toUpperCase(),
              ...data[key][j],
              desc: `사용량 : ${data[key][j].use}% ${util.addCommas(
                bytesToGigabytes(data[key][j].used).toFixed(2)
              )}/${util.addCommas(
                bytesToGigabytes(data[key][j].size).toFixed(2)
              )} GB`,
              charts: [
                {
                  id: "USAGE",
                  value: (data[key][j].used / data[key][j].size) * 100,
                  color: "#0097B7",
                },
                {
                  id: "AVAILABLE",
                  value: (data[key][j].available / data[key][j].size) * 100,
                  color: "#334D80",
                },
              ],
            });
          }
        }
      }
      tempList.push(temp);
    }
    console.log(tempList);
    setServerList([...tempList]);
    setIsNeedUpdate(!isNeedUpdate);*/
  }

  return (
    <main
      style={{ height: "100vh" }}
      className="bg-freiscop text-white position-relative"
    >
      {isDisplayReset && (
        <div
          className="position-fixed vh-100 vw-100"
          style={{ zIndex: 999, background: "#000000" }}
        />
      )}
      <div className="vh-100 d-flex flex-column">
        <p className="h2 font-weight-bold mb-0 pt-3 px-3">DASH BOARD</p>
        <div className="d-flex flex-row flex-wrap">
          <div
            className="mh-100 w-100 row m-0 p-0"
            style={{ maxHeight: `${window.innerHeight - 88} !important` }}
          >
            {serverList.map((server, index) => {
              const url = new URL(_serverUrlList[index]);
              let serverName = url.hostname.split(".");
              serverName = serverName[0] + "." + serverName[1];

              return (
                <div key={index} className="col-6 m-0 p-3">
                  <div
                    style={{
                      background: "rgba(255,255,255,0.05)",
                    }}
                    className="p-3 rounded-lg shadow"
                  >
                    <p className="h4 font-weight-bold">
                      <i className="fa-solid fa-circle-info mr-2 text-white" />
                      {serverName} [{server.platform}]
                    </p>
                    <div className="row m-0 p-0">
                      <div className="col-6 m-0 p-0">
                        {server.pieCharts.map((data, index) => {
                          return (
                            <div key={index} className="height-250 mb-5">
                              <p className="text-light h6 mb-0 font-weight-bold">
                                {data.id} {data.mount}
                              </p>
                              <p
                                className="mb-0"
                                style={{ color: "rgba(255,255,255,0.6)" }}
                              >
                                {data.desc}
                              </p>

                              <div className="position-relative w-100 h-100">
                                <p className="position-absolute h3 mb-0 position-center font-weight-bold">
                                  {data.use}%
                                </p>
                                <div className="position-absolute width-200 height-200 position-center">
                                  <MyResponsivePie data={data.charts} />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-6 m-0 p-0">
                        <div className="h-100 my-3"></div>
                      </div>

                      {server.lineCharts.map((data) => {
                        return (
                          <div
                            key={data.id}
                            className="col-6 m-0 p-0 height-300"
                          >
                            <p className="text-light h6 mb-0 font-weight-bold">
                              {data.name}
                            </p>
                            <p
                              className="mb-0"
                              style={{ color: "rgba(255,255,255,0.6)" }}
                            >
                              {data.desc}
                            </p>
                            <MyResponsiveLine
                              data={data}
                              keys={data.keys}
                              indexBy="timestamp"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </main>
  );
}

const MyResponsivePie = ({ data /* see data tab */, innerRadius }) => {
  return (
    <ResponsivePie
      data={data}
      colors={(event) => {
        return event.data.color;
      }}
      width={200}
      height={200}
      startAngle={0}
      endAngle={360}
      innerRadius={innerRadius || 0.8}
      enableArcLabels={false}
      enableArcLinkLabels={false}
    />
  );
};

const MyResponsiveLine = ({ data, keys, indexBy }) => {
  return (
    <ResponsiveLine
      data={[data]}
      colors={"#0097B7"}
      theme={{
        textColor: "#ffffff", // 기본 글자색 설정
        fontSize: 10, // 전체 텍스트 크기
        axis: {
          domain: {
            line: {
              stroke: "#ffffff", // 축 선 색상
              strokeWidth: 1,
            },
          },
          ticks: {
            line: {
              stroke: "#ffffff", // 눈금 선 색상
              strokeWidth: 1,
            },
            text: {
              fill: "#ffffff", // 축 눈금 텍스트 색상
            },
          },
          legend: {
            text: {
              fill: "#ffffff", // 축 이름 텍스트 색상
            },
          },
        },
        legends: {
          text: {
            fill: "#ffffff", // 범례 텍스트 색상
          },
        },
        tooltip: {
          container: {
            background: "#333333", // 툴팁 배경색
            color: "#ffffff", // 툴팁 텍스트 색상
            fontSize: 12, // 툴팁 텍스트 크기
          },
        },
      }}
      yFormat=" >-.2f"
      maxValue={100}
      margin={{ top: 20, right: 20, bottom: 50, left: 30 }}
      //
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="data.yFormatted"
      pointLabelYOffset={-12}
      enableTouchCrosshair={true}
      useMesh={true}
      yScale={{
        type: "linear", // y축을 숫자형 스케일로 설정
        min: 0, // y축의 최소값을 0으로 고정
        max: 100, // y축의 최대값을 100으로 고정
      }}
      tooltip={({ point }) => {
        return (
          <div
            className="p-2 rounded text-white"
            style={{ background: "rgba(0,0,0,.8)" }}
          >
            {point.data.xFormatted} : {point.data.realValue.toFixed(2)}%
          </div>
        );
      }}
    />
  );
};
